import {Speaker} from './speaker.model';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ApiResponse} from './global.model';
import {Product} from './product.model';

export class Course {
    public course_id: string;
    public title: string;
    public image: string;
    public cover?: string;
    public content?: number;
    public speakers?: Speaker[];
    public tors?: string[];
    public module_tab?: number;
    public inquiry_tab?: number;
    public discussion_tab?: number;
    public discussion_url?: string;
    public leaderboard_tab?: number;
    public raport_tab?: number;
    public certificate_tab?: number;
    public consultation_tab?: number;
    public opened?: string;
    public closed?: string;
    public started?: string;
    public ended?: string;
    public quota?: number;
    public registered?: boolean;
    public duration?: string;
    public material?: string;
    public company?: string;
    public docs?: string;
    public access?: string;
}

export class CourseInfo {
    public memberType: number;
    public memberColor: string;
    public hasDiscussion?: boolean;
    public data: Course;
}

export class CourseHeading {
    public class: string;
    public type: string;
    public id: string;
    public data: CourseHeadingData;
}

export class CourseHeadingData {
    public id?: string;
    public title?: string;
    public time?: number;
    public embed?: SafeResourceUrl;
    public passed?: boolean;
}

export class CourseModule {
    public course_module_item_id: string;
    public material_id: string;
    public evaluation_id: string;
    public type: number;
    public sub_type: string;
    public title: string;
    public visibility: number;
    public visible_from: string;
    public visible_to: string;
    public is_open: number;
    public is_active: boolean;
    public children: CourseModule[];
}

export class CourseDiscussion {
    public course_discussion_id: string;
    public question: string;
    public answer: string;
    public speakers: Speaker[];
    public created: string;
    public status: number;
}

export class CourseQuestion {
    public course_inquiry_id?: string;
    public answerer_type?: string;
    public answerer_code?: number;
    public answerer_color?: string;
    public answerer_name?: string;
    public answerer_image?: string;
    public member_name?: string;
    public member_image?: string;
    public anonymous: number;
    public question: string;
    public answer?: string;
    public mine?: boolean;
    public created?: string;
    public status?: number;
    public attached?: number;
    public selected?: boolean;
}

export class CourseEvaluation {
    public id: string;
    public type: number;
    public question: string;
    public options: CourseEvaluationOption[];
    public sequence: number;
    public answer?: string;
    public member_answer?: string;
    public correct_answer?: string;
    public is_correct?: boolean;
    public score?: number;
}

export class CourseEvaluationOption {
    public value: string;
    public label: string;
}

export class CourseEvaluationResult {
    public member_id: string;
    public member_name: string;
    public score: number;
    public type: number;
    public status: number;
}

export class CourseTestimonial {
    public course_testimonial_id?: string;
    public member_name?: string;
    public member_color?: string;
    public rating: number;
    public message: string;
    public created?: string;
}

export class CourseConsultationGroup {
    public date: string;
    public from: string;
    public items: CourseConsultation[];
    public is_active?: boolean;
}

export class CourseConsultation {
    public schedule_id: string;
    public course_id?: string;
    public member?: string;
    public date: string;
    public from: string;
    public to: string;
    public price: number;
    public price_promo: number;
    public unique_code: number;
    public phone: string;
    public status: number;
    public member_type?: number
    public name?: string;
    public photo?: string;
    public passed?: boolean;
    public ordered?: string;
    public ended?: string;
    public is_active?: boolean;
}

export class CourseLeaderboard {
    public member_id: string;
    public member_name: string;
    public member_photo: string;
    public score: number;
    public total: number;
    public duration: number;
    public ranking: number;
}

export class CourseMaterial {
    public content: string;
}

export class CourseResponse extends ApiResponse {
    data: {
        member_type: number,
        member_color: string,
        courses: Course
    };
}

export class CourseAction {
    public start: number;
    public length: number;
    public sort: string;
    public sortLabel: string;
    public filter: string;
    public filterLabel: string;
    public filteringShow: boolean;
    public sortingShow: boolean;
}

export class CourseCategory {
    public course_category_id: string;
    public types: string;
    public names: string;
    public images: string;
    public is_default: boolean;
}

export class CourseReadyResponse extends ApiResponse {
    data: {
        products: Product[]
    };
}

export class CourseConsultationResponse extends ApiResponse {
    data: {
        consultation: CourseConsultation,
        access?: boolean,
        timeout?: number,
        schedules?: string[]
    };
}

export class CourseConsultationSchedulesResponse extends ApiResponse {
    data: {
        schedules: CourseConsultation[]
    };
}

export class CourseConsultationGroupResponse extends ApiResponse {
    data: {
        consultations: CourseConsultationGroup[]
    };
}

export class CourseCategoriesResponse extends ApiResponse {
    data: {
        course_categories: CourseCategory[]
    };
}
