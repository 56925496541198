import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home', pathMatch: 'full'
    }, {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
    }, {
        path: 'signin',
        loadChildren: () => import('./pages/signin/signin.module').then(m => m.SigninPageModule)
    }, {
        path: 'signup',
        loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule)
    },

    // === CATALOG ===
    {
        path: 'catalog/search',
        loadChildren: () => import('./pages/catalog/catalog-search/catalog-search.module').then(m => m.CatalogSearchPageModule)
    }, {
        path: 'catalog/content/:id',
        loadChildren: () => import('./pages/catalog/catalog-content/catalog-content.module').then(m => m.CatalogContentPageModule)
    }, {
        path: 'catalog/courses',
        loadChildren: () => import('./pages/catalog/catalog-courses/catalog-courses.module').then(m => m.CatalogCoursesPageModule)
    }, {
        path: 'catalog/groups',
        loadChildren: () => import('./pages/catalog/catalog-groups/catalog-groups.module').then(m => m.CatalogGroupsPageModule)
    }, {
        path: 'catalog/consultations',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/catalog/catalog-consultations/catalog-consultations.module').then(m => m.CatalogConsultationsPageModule)
    }, {
        path: 'catalog/categories',
        loadChildren: () => import('./pages/catalog/catalog-categories/catalog-categories.module').then( m => m.CatalogCategoriesPageModule)
    }, {
        path: 'catalog/monthly',
        loadChildren: () => import('./pages/catalog/catalog-monthly/catalog-monthly.module').then( m => m.CatalogMonthlyPageModule)
    }, {
        path: 'catalog/academic',
        loadChildren: () => import('./pages/catalog/catalog-academic/catalog-academic.module').then( m => m.CatalogAcademicPageModule)
    }, {
        path: 'catalog/courses/:id',
        loadChildren: () => import('./pages/catalog/catalog-course/catalog-course.module').then(m => m.CatalogCoursePageModule)
    }, {
        path: 'catalog/groups/:id',
        loadChildren: () => import('./pages/catalog/catalog-group/catalog-group.module').then(m => m.CatalogGroupPageModule)
    }, {
        path: 'catalog/consultations/:id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/catalog/catalog-consultation/catalog-consultation.module').then(m => m.CatalogConsultationPageModule)
    }, {
        path: 'catalog/:id/reviews',
        loadChildren: () => import('./pages/catalog/catalog-review/catalog-review.module').then(m => m.CatalogReviewPageModule)
    },

    // === CLASSROOM ===

    // CLASSROOM - COURSE
    {
        path: 'classroom/course/:id',
        loadChildren: () => import('./pages/classroom/course/course-detail/course-detail.module').then(m => m.CourseDetailPageModule)
    }, {
        path: 'classroom/course/:id/leaderboard',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-leaderboard/course-detail-leaderboard.module').then(m => m.CourseDetailLeaderboardPageModule)
    }, {
        path: 'classroom/course/:id/raport',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-raport/course-detail-raport.module').then(m => m.CourseDetailRaportPageModule)
    }, {
        path: 'classroom/course/:id/raport/:member_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-raport/course-detail-raport.module').then(m => m.CourseDetailRaportPageModule)
    }, {
        //     path: 'classroom/course/:id/raport/:member_id/statistic/:indicator_id',
        // tslint:disable-next-line:max-line-length
        //     loadChildren: './pages/classroom/course/course-detail-raport-statistic/course-detail-raport-statistic.module#CourseDetailRaportStatisticPageModule'
        // }, {
        path: 'classroom/course/:id/question/add',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-question-add/course-detail-question-add.module').then(m => m.CourseDetailQuestionAddPageModule)
    }, {
        path: 'classroom/course/:id/question/:course_inquiry_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-question/course-detail-question.module').then(m => m.CourseDetailQuestionPageModule)
    }, {
        path: 'classroom/course/:id/testimonial',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-testimonial/course-detail-testimonial.module').then(m => m.CourseDetailTestimonialPageModule)
    }, {
        path: 'classroom/course/:id/chat',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-chat/course-detail-chat.module').then(m => m.CourseDetailChatPageModule)
    }, {
        path: 'classroom/course/:id/chat/inquiries',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-chat-inquiry/course-detail-chat-inquiry.module').then(m => m.CourseDetailChatInquiryPageModule)
    }, {
        path: 'classroom/course/:id/resume',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-resume/course-detail-resume.module').then(m => m.CourseDetailResumePageModule)
    }, {
        path: 'classroom/course/:id/discussion',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-discussion/course-detail-discussion.module').then(m => m.CourseDetailDiscussionPageModule)
    }, {
        path: 'classroom/course/:id/discussion/:course_discussion_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-discussion-detail/course-detail-discussion-detail.module').then(m => m.CourseDetailDiscussionDetailPageModule)
    }, {
        path: 'classroom/course/:id/material/:material_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-material/course-detail-material.module').then(m => m.CourseDetailMaterialPageModule)
    }, {
        path: 'classroom/course/:id/link/:link_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-link/course-detail-link.module').then( m => m.CourseDetailLinkPageModule)
    }, {
        path: 'classroom/course/:id/evaluation/:module_item_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-evaluation/course-detail-evaluation.module').then(m => m.CourseDetailEvaluationPageModule)
    }, {
        path: 'classroom/course/:id/evaluation/:module_item_id/results',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-evaluation-result/course-detail-evaluation-result.module').then(m => m.CourseDetailEvaluationResultPageModule)
    }, {
        path: 'classroom/course/:id/evaluation/:module_item_id/results/:member_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-evaluation-score/course-detail-evaluation-score.module').then(m => m.CourseDetailEvaluationScorePageModule)
    }, {
        path: 'classroom/course/:id/evaluation/:module_item_id/summary',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/course/course-detail-evaluation-summary/course-detail-evaluation-summary.module').then(m => m.CourseDetailEvaluationSummaryPageModule)
    },
    // CLASSROOM - PODCAST
    {
        path: 'classroom/podcast/video/:id',
        loadChildren: () => import('./pages/classroom/podcast/podcast-video/podcast-video.module').then(m => m.PodcastVideoPageModule)
    },

    // CLASSROOM - GROUP
    {
        path: 'classroom/group/:id',
        loadChildren: () => import('./pages/classroom/group/group-detail/group-detail.module').then(m => m.GroupDetailPageModule)
    }, {
        path: 'classroom/group/:id/question/add',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/group/group-detail-question-add/group-detail-question-add.module').then(m => m.GroupDetailQuestionAddPageModule)
    }, {
        path: 'classroom/group/:id/question/:course_inquiry_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/group/group-detail-question/group-detail-question.module').then(m => m.GroupDetailQuestionPageModule)
    }, {
        path: 'classroom/group/:id/testimonial',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/group/group-detail-testimonial/group-detail-testimonial.module').then(m => m.GroupDetailTestimonialPageModule)
    }, {
        path: 'classroom/group/:id/chat',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/group/group-detail-chat/group-detail-chat.module').then(m => m.GroupDetailChatPageModule)
    }, {
        path: 'classroom/group/:id/resume',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/group/group-detail-resume/group-detail-resume.module').then(m => m.GroupDetailResumePageModule)
    }, {
        path: 'classroom/group/:id/discussion',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/group/group-detail-discussion/group-detail-discussion.module').then(m => m.GroupDetailDiscussionPageModule)
    }, {
        path: 'classroom/group/:id/discussion/:course_discussion_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/group/group-detail-discussion-detail/group-detail-discussion-detail.module').then(m => m.GroupDetailDiscussionDetailPageModule)
    }, {
        path: 'classroom/group/:id/material/:material_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/group/group-detail-material/group-detail-material.module').then(m => m.GroupDetailMaterialPageModule)
    },

    // CLASSROOM - CONSULTATION
    {
        path: 'classroom/consultation/:id/question/add',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-question-add/consultation-detail-question-add.module').then(m => m.ConsultationDetailQuestionAddPageModule)
    }, {
        path: 'classroom/consultation/:id/question/:course_inquiry_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-question/consultation-detail-question.module').then(m => m.ConsultationDetailQuestionPageModule)
    }, {
        path: 'classroom/consultation/:id/testimonial',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-testimonial/consultation-detail-testimonial.module').then(m => m.ConsultationDetailTestimonialPageModule)
    }, {
        path: 'classroom/consultation/:id/chat',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-chat/consultation-detail-chat.module').then(m => m.ConsultationDetailChatPageModule)
    }, {
        path: 'classroom/consultation/:id/material/:material_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-material/consultation-detail-material.module').then(m => m.ConsultationDetailMaterialPageModule)
    }, {
        path: 'classroom/consultation/:id/evaluation/:module_item_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-evaluation/consultation-detail-evaluation.module').then(m => m.ConsultationDetailEvaluationPageModule)
    }, {
        path: 'classroom/consultation/:id/evaluation/:module_item_id/results',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-evaluation-result/consultation-detail-evaluation-result.module').then(m => m.ConsultationDetailEvaluationResultPageModule)
    }, {
        path: 'classroom/consultation/:id/evaluation/:module_item_id/results/:member_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-evaluation-score/consultation-detail-evaluation-score.module').then(m => m.ConsultationDetailEvaluationScorePageModule)
    }, {
        path: 'classroom/consultation/:id/evaluation/:module_item_id/summary',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-evaluation-summary/consultation-detail-evaluation-summary.module').then(m => m.ConsultationDetailEvaluationSummaryPageModule)
    }, {
        path: 'classroom/consultation/:id/booking',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-booking/consultation-detail-booking.module').then(m => m.ConsultationDetailBookingPageModule)
    }, {
        path: 'classroom/consultation/:id/checkout/:schedule_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-checkout/consultation-detail-checkout.module').then(m => m.ConsultationDetailCheckoutPageModule)
    }, {
        path: 'classroom/consultation/:id/invoice/:schedule_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-invoice/consultation-detail-invoice.module').then(m => m.ConsultationDetailInvoicePageModule)
    }, {
        path: 'classroom/consultation/:id/booked/:schedule_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-booked/consultation-detail-booked.module').then(m => m.ConsultationDetailBookedPageModule)
    }, {
        path: 'classroom/consultation/:id/:schedule_id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail-consultation/consultation-detail-consultation.module').then(m => m.ConsultationDetailConsultationPageModule)
    }, {
        path: 'classroom/consultation/:id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/classroom/consultation/consultation-detail/consultation-detail.module').then(m => m.ConsultationDetailPageModule)
    },

    // === PROFILE ===
    {
        path: 'profile/update',
        loadChildren: () => import('./pages/profile/profile-update/profile-update.module').then(m => m.ProfileUpdatePageModule)
    }, {
        path: 'profile/phone',
        loadChildren: () => import('./pages/profile/profile-phone/profile-phone.module').then(m => m.ProfilePhonePageModule)
    }, {
        path: 'profile/email',
        loadChildren: () => import('./pages/profile/profile-email/profile-email.module').then(m => m.ProfileEmailPageModule)
    }, {
        path: 'profile/password',
        loadChildren: () => import('./pages/profile/profile-password/profile-password.module').then(m => m.ProfilePasswordPageModule)
    },

    // === TRANSACTION ===
    {
        path: 'transaction/cart',
        loadChildren: () => import('./pages/transaction/transaction-cart/transaction-cart.module').then(m => m.TransactionCartPageModule)
    }, {
        path: 'transaction/checkout',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/transaction/transaction-checkout/transaction-checkout.module').then(m => m.TransactionCheckoutPageModule)
    }, {
        path: 'transaction/:id',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/transaction/transaction-detail/transaction-detail.module').then(m => m.TransactionDetailPageModule)
    }, {
        path: 'transaction/:id/confirm',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/transaction/transaction-confirm/transaction-confirm.module').then(m => m.TransactionConfirmPageModule)
    }, {
        path: 'transaction/:id/payment-method',
        loadChildren: () => import('./pages/transaction/transaction-payment-method/transaction-payment-method.module').then(m => m.PaymentMethodPageModule)
    },

    // === PARTNER ===
    {
        path: 'partners',
        loadChildren: () => import('./pages/partner/partner-list/partner-list.module').then( m => m.PartnerListPageModule)
    },
    {
        path: 'm/:url/banners',
        loadChildren: () => import('./pages/partner/partner-banner/partner-banner.module').then( m => m.PartnerBannerPageModule)
    },
    {
        path: 'm/:url/categories',
        loadChildren: () => import('./pages/partner/partner-category/partner-category.module').then( m => m.PartnerCategoryPageModule)
    },
    {
        path: 'm/:url/search',
        loadChildren: () => import('./pages/partner/partner-search/partner-search.module').then( m => m.PartnerSearchPageModule)
    },
    {
        path: 'm/:url/content/:id',
        loadChildren: () => import('./pages/partner/partner-content/partner-content.module').then( m => m.PartnerContentPageModule)
    },
    {
        path: 'm/:url',
        loadChildren: () => import('./pages/partner/partner-detail/partner-detail.module').then( m => m.PartnerDetailPageModule)
    },

    // === OTHER ===
    {
        path: 'raport-info',
        loadChildren: () => import('./pages/classroom/raport-info/raport-info.module').then(m => m.RaportInfoPageModule)
    }, {
        path: 'info/contact',
        loadChildren: () => import('./pages/info/info-contact/info-contact.module').then(m => m.InfoContactPageModule)
    }, {
        path: 'info/keluhan',
        loadChildren: () => import('./pages/info/info-keluhan/info-keluhan.module').then(m => m.InfoKeluhanPageModule)
    }, {
        path: 'info/pesan-kelas',
        loadChildren: () => import('./pages/info/info-pesan-kelas/info-pesan-kelas.module').then(m => m.InfoPesanKelasPageModule)
    }, {
        path: 'info/password-reset',
        loadChildren: () => import('./pages/info/info-password-reset/info-password-reset.module').then(m => m.InfoPasswordResetPageModule)
    }, {
        path: 'recomendation',
        loadChildren: () => import('./pages/recomendation/recomendation.module').then(m => m.RecomendationPageModule)
    }, {
        path: 'course-ready',
        loadChildren: () => import('./pages/course-ready/course-ready.module').then(m => m.CourseReadyPageModule)
    }, {
        path: 'privacy-policy',
        loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
    }, {
        path: 'support',
        loadChildren: () => import('./pages/support/support.module').then(m => m.SupportPageModule)
    }, {
        path: 'mitra',
        loadChildren: () => import('./pages/mitra/mitra.module').then(m => m.MitraPageModule)
    }
    , {
        path: ':guid',
        loadChildren: () => import('./pages/dynamic-link/dynamic-link.module').then( m => m.DynamicLinkPageModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
