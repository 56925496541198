import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    public apiVersion = {
        v000: '0.0.0',
        v001: '0.0.1'
    };

    private urlDev = `https://dev-api.fammi.ly`;
    private urlProd = `https://api.fammi.ly`;
    private urlPayment = `http://payment.fammi.ly:1200`;
    public apiUrl = environment.apiUrl;

    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
    }

    public paymentRequest(data, param = null) {
        return new Promise(async (resolve, reject) => {
            const auth = await this.authService.getAuth();
            const options = {
                headers: new HttpHeaders(),
                params: new HttpParams()
            };

            if (param) {
                // tslint:disable-next-line:forin
                for (const key in param) {
                    options.params = options.params.append(key, param[key]);
                }
            }

            this.httpClient.post(`${this.urlPayment}`, data, options).subscribe((response: any) => {
                resolve(response);
            }, async (response: any) => {
                console.error(`HTTP PAYMENT REQUEST ERROR: \r\nRESPONSE ${JSON.stringify(response, null, 2)}`);
                reject(response);
            });
        }).then(results => {
            return results;
        });
    }

    public post(url, param, data, version = null) {
        return new Promise(async (resolve, reject) => {
            const auth = await this.authService.getAuth();
            const options = {
                headers: new HttpHeaders(),
                params: new HttpParams()
            };

            // tslint:disable-next-line:max-line-length
            options.headers = options.headers.append('enctype', 'multipart/form-data; boundary=----WebKitFormBoundary' + this.generateCode(11));
            if (auth) {
                options.headers = options.headers.append('Auth-Id', auth.id);
                options.headers = options.headers.append('Auth-Token', auth.token);
            }

            const date = new Date();
            options.params = options.params.append('_time', String(date.getTime()));
            if (param) {
                // tslint:disable-next-line:forin
                for (const key in param) {
                    options.params = options.params.append(key, param[key]);
                }
            }

            if (version === null) {
                version = this.apiVersion.v000;
            }

            this.httpClient.post(`${this.apiUrl}/${version}/${url}`, data, options).subscribe((response: any) => {
                resolve(response);
            }, async (response: any) => {
                console.error(`HTTP POST ERROR: ${this.apiUrl}/${version}/${url}\r\nRESPONSE ${JSON.stringify(response, null, 2)}`);
                if (typeof response.error === 'object' && typeof response.error.status === 'object') {
                    if (typeof response.error.status.code !== 'undefined' && response.error.status.code === 99) {
                        await this.authService.logout();
                        this.router.navigateByUrl(`/home/catalog`);
                    }
                }
                reject(response);
            });
        }).then(results => {
            return results;
        });
    }

    public put(url, param, data, version = null) {
        return new Promise(async (resolve, reject) => {
            const auth = await this.authService.getAuth();
            const options = {
                headers: new HttpHeaders(),
                params: new HttpParams()
            };

            // tslint:disable-next-line:max-line-length
            options.headers = options.headers.append('enctype', 'multipart/form-data; boundary=----WebKitFormBoundary' + this.generateCode(11));
            if (auth) {
                options.headers = options.headers.append('Auth-Id', auth.id);
                options.headers = options.headers.append('Auth-Token', auth.token);
            }

            const date = new Date();
            options.params = options.params.append('_time', String(date.getTime()));
            if (param) {
                // tslint:disable-next-line:forin
                for (const key in param) {
                    options.params = options.params.append(key, param[key]);
                }
            }

            if (version === null) {
                version = this.apiVersion.v000;
            }

            this.httpClient.put(`${this.apiUrl}/${version}/${url}`, data, options).subscribe((response: any) => {
                resolve(response);
            }, async (response: any) => {
                console.error(`HTTP PUT ERROR: ${this.apiUrl}/${version}/${url}\r\nRESPONSE ${JSON.stringify(response, null, 2)}`);
                if (typeof response.error === 'object' && typeof response.error.status === 'object') {
                    if (typeof response.error.status.code !== 'undefined' && response.error.status.code === 99) {
                        await this.authService.logout();
                        this.router.navigateByUrl(`/home/catalog`);
                    }
                }
                reject(response);
            });
        }).then(results => {
            return results;
        });
    }

    public get(url, param, version = null, type = null) {
        return new Promise(async (resolve, reject) => {
            const auth = await this.authService.getAuth();
            const options = {
                headers: new HttpHeaders(),
                params: new HttpParams(),
                responseType: 'json' as any
            };

            if (auth) {
                options.headers = options.headers.append('Auth-Id', auth.id);
                options.headers = options.headers.append('Auth-Token', auth.token);
            }

            const date = new Date();
            options.params = options.params.append('_time', String(date.getTime()));
            if (param) {
                // tslint:disable-next-line:forin
                for (const key in param) {
                    options.params = options.params.append(key, param[key]);
                }
            }

            if (version === null) {
                version = this.apiVersion.v000;
            }

            if (type !== null && ['arraybuffer', 'blob', 'json', 'text'].indexOf(type) > -1) {
                options.responseType = type;
            }

            this.httpClient.get(`${this.apiUrl}/${version}/${url}`, options).subscribe((response: any) => {
                resolve(response);
            }, async (response: any) => {
                console.error(`HTTP GET ERROR: ${this.apiUrl}/${version}/${url}\r\nRESPONSE ${JSON.stringify(response, null, 2)}`);
                if (typeof response.error === 'object' && typeof response.error.status === 'object') {
                    if (typeof response.error.status.code !== 'undefined' && response.error.status.code === 99) {
                        await this.authService.logout();
                        this.router.navigateByUrl(`/home/catalog`);
                    }
                }
                reject(response);
            });
        }).then(results => {
            return results;
        });
    }

    private generateCode(length) {
        const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let text = '';
        for (let i = 0; i < length; i++) {
            text += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return text;
    }

}
