import {Speaker} from './speaker.model';
import {Course, CourseConsultationGroup, CourseModule, CourseTestimonial} from './course.model';
import {ApiResponse} from './global.model';
import {Partner} from './partner.model';

export class Product {
    public product_id: string;
    public type: number;
    public name: string;
    public price: number;
    public price_desc?: string;
    public image: string;
    public from: string;
    public to: string;
    public rating: number;
    public headlines?: string;
    public descriptions?: string;
    public about?: string;
    public content?: number;
    public member_total?: number;
    public courses?: Course[];
    public speakers?: Speaker[];
    public testimonials?: CourseTestimonial[];
    public reasons?: ProductReason[];
    public course_modules?: CourseModule[];
    public consultations?: CourseConsultationGroup[];
}

export class ProductState {
    public beforeSale: boolean;
    public afterSale: boolean;
    public onSale: boolean;
    public outQuota: boolean;
}

export class ProductReason {
    public reason: string;
}

export class ProductTestimonial {
    public name: string;
    public courses: string;
    public speakers: string;
    public rating: number;
    public message: string;
}

export class CatalogDetailData {
    public products: Product;
}

export class CatalogDetailResponse extends ApiResponse {
    public data: CatalogDetailData;
}

export class CatalogResponse extends ApiResponse {
    public data: {
        products: Product[]
    };
}

export class CourseEnterResponse extends ApiResponse {
}

export class PartnersResponse extends ApiResponse {
    public data: {
        partners: Partner[]
    };
}

export class CatalogAction {
    public start: number;
    public length: number;
    public sort: string;
    public sortLabel: string;
    public filter: string;
    public filterLabel: string;
    public filteringShow: boolean;
    public sortingShow: boolean;
}
