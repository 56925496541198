import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingController, ModalController, NavController, ToastController} from '@ionic/angular';
import {Network} from '@ionic-native/network/ngx';
import {HttpService} from '../../../core/http/http.service';
import {CartService} from '../../../core/helper/cart.service';
import {AuthService} from '../../../core/auth/auth.service';
import {HelperService} from '../../../core/helper/helper.service';
import {Product} from '../../../models/product.model';
import {ApiResponse} from '../../../models/global.model';

@Component({
    selector: 'app-catalog-buy',
    templateUrl: './catalog-buy.page.html',
    styleUrls: ['./catalog-buy.page.scss'],
})
export class CatalogBuyPage implements OnInit {

    @Input('product') public product: Product;

    public catalog: {
        processing: boolean,
        loading: boolean,
        data: {
            code: string
        },
        content(code: number): string,
        input(): void,
        order(): void,
        buy(): void
    } = {
        processing: false,
        loading: false,
        data: {
            code: ''
        },
        content: (code) => {
            let content = '';
            switch (code) {
                case 1: content = '/classroom/course/'; break;
                case 2: content = '/classroom/podcast/video/'; break;
                case 3: content = '/classroom/group/'; break;
                case 4: content = '/classroom/consultation/'; break;
                default: break;
            }
            return content;
        },
        input: async () => {
            const that = this.catalog;
            if (this.product.courses === null || this.product.courses.length === 0) {
                return false;
            }
            const url = `members/courses/${this.product.courses[0].course_id}/join`;
            const param = {};
            const data = new FormData();

            if (that.processing) {
                return false;
            }
            that.processing = true;

            const loading = await this.loadingController.create({
                message: 'Memuat data...',
                translucent: true
            });
            loading.present();

            data.append('code', that.data.code.toUpperCase());

            this.httpService.post(url, param, data).then(async (response: CourseJoinResponse) => {
                that.processing = false;
                loading.dismiss();
                if (response.success) {
                    this.navigateTo(`${this.catalog.content(this.product.courses[0].content)}${this.product.courses[0].course_id}`);
                    this.dismiss({action: 'reload'});
                    this.presentToast(response.message, 'dark');
                } else {
                    this.presentToast(response.message, 'danger');
                }
            }, (error) => {
                that.processing = false;
                loading.dismiss();
                console.error(error);
                this.presentToast(`Gagal mengirim data`, `danger`);
            });
        },
        order: () => {
            const that = this.catalog;
            this.navigateTo('/info/pesan-kelas');
            this.dismiss();
        },
        buy: async () => {
            const that = this.catalog;
            await this.cartService.add(this.product);
            this.navigateTo(`/transaction/checkout`);
            this.dismiss();
        }
    };

    constructor(
        private network: Network,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private loadingController: LoadingController,
        private navController: NavController,
        private toastController: ToastController,
        private modalController: ModalController,
        private httpService: HttpService,
        private authService: AuthService,
        public helperService: HelperService,
        private cartService: CartService
    ) {}

    ngOnInit() {
    }

    public dismiss(data: any = null) {
        this.modalController.dismiss(data);
    }

    public navigateTo(url: string) {
        this.router.navigateByUrl(url);
    }

    public navigateBack(url: string) {
        this.navController.navigateBack(url);
    }

    public goBack() {
        this.navController.back();
    }

    public async presentToast(message: string, type: string = 'default', duration: number = 3000) {
        let cssClass = 'toast-custom';
        switch (type) {
            case 'success': cssClass += ' success'; break;
            case 'warning': cssClass += ' warning'; break;
            case 'danger': cssClass += ' danger'; break;
            default: break;
        }
        const toast = await this.toastController.create({ message, duration, cssClass });
        toast.present();
    }

}

export class CourseJoinResponse extends ApiResponse {
}
