import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CourseCategory} from '../../../models/course.model';

@Component({
    selector: 'app-category-content',
    templateUrl: './category-content.page.html',
    styleUrls: ['./category-content.page.scss'],
})
export class CategoryContentPage implements OnInit {

    @Input('data') public data: CourseCategory[];
    @Input('selected') public selected: CourseCategory;

    constructor(
        private modalController: ModalController
    ) {
    }

    ngOnInit() {
    }

    public select(category: CourseCategory) {
        this.modalController.dismiss(category);
    }

    public dismiss() {
        this.modalController.dismiss();
    }

}
