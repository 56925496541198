import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {Auth} from '../../models/auth.model';
import {Member} from '../../models/member.model';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private tokenDev = `FAMMI-DEV`;
    private tokenProd = `FAMMI-V2`;

    private authData = 'AUTH-DATA';
    private userData = 'USER-DATA';

    public authState$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private platform: Platform,
        private storage: Storage,
        private router: Router
    ) {
        if (environment.production) {
            this.authData = `${this.tokenProd}-${this.authData}`;
            this.userData = `${this.tokenProd}-${this.userData}`;
        } else {
            this.authData = `${this.tokenDev}-${this.authData}`;
            this.userData = `${this.tokenDev}-${this.userData}`;
        }
        this.platform.ready().then(_ => {
            this.checkToken();
        });
    }

    public async login(auth: Auth, data: Member) {
        await this.storage.set(this.authData, JSON.stringify(auth));
        await this.storage.set(this.userData, JSON.stringify(data));
        this.authState$.next(true);
    }

    public async logout() {
        await this.storage.remove(this.authData);
        await this.storage.remove(this.userData);
        this.authState$.next(false);
    }

    public getAuthStateObserver(): Observable<boolean> {
        return this.authState$.asObservable();
    }

    public isAuthenticated() {
        return this.authState$.value;
    }

    public async setData(data: Member) {
        await this.storage.set(this.userData, JSON.stringify(data));
    }

    public async getAuth(): Promise<Auth> {
        const auth = await this.storage.get(this.authData);
        let data = null;
        if (auth) {
            data = JSON.parse(auth);
        }
        return data;
    }

    public async getData(): Promise<Member> {
        const member = await this.storage.get(this.userData);
        let data = null;
        if (member) {
            data = JSON.parse(member);
        }
        return data;
    }

    private checkToken() {
        this.storage.get(this.authData).then(res => {
            if (res) {
                this.authState$.next(true);
            } else {
                this.logout();
            }
        });
    }
}
