import {Component, ViewChild} from '@angular/core';

import {IonRouterOutlet, Platform, ToastController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AuthService} from './core/auth/auth.service';
import {HelperService} from './core/helper/helper.service';
import {HttpService} from './core/http/http.service';
import {FCM} from '@ionic-native/fcm/ngx';
import {Auth} from './models/auth.model';
import {Network} from '@ionic-native/network/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    public connectionState = true;
    public disconnectSubscription = this.network.onDisconnect().subscribe(() => {
        this.connectionState = false;
    });
    public connectSubscription = this.network.onConnect().subscribe(() => {
        this.connectionState = true;
        this.checkAppVersion();
    });

    @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

    private auth: Auth = null;

    public isLatest = true;
    public forceUpdate = false;
    public skipUpdate = false;

    constructor(
        private network: Network,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private toastController: ToastController,
        private authService: AuthService,
        public helperService: HelperService,
        private httpService: HttpService,
        private fcm: FCM
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(async () => {
            this.statusBar.styleLightContent();

            this.checkAppVersion();

            this.platform.backButton.subscribeWithPriority(0, () => {
                const component = this.routerOutlet.activatedRoute.component;
                if (this.routerOutlet && this.routerOutlet.canGoBack()) {
                    this.routerOutlet.pop();
                } else {
                    navigator['app'].exitApp();
                }
            });

            this.auth = await this.authService.getAuth();
            if (this.auth) {
                if (this.helperService.isNative()) {
                    const token = await this.fcm.getToken();
                    console.log('updateFcmToken', token);
                    this.updateFcmToken(token);
                }
            }
            if (this.helperService.isNative()) {
                this.fcm.onTokenRefresh().subscribe(async (token) => {
                    console.log('onTokenRefresh', token);
                    this.auth = await this.authService.getAuth();
                    if (this.auth) {
                        this.updateFcmToken(token);
                    }
                });

                this.fcm.onNotification().subscribe((data) => {
                    console.log('onNotification', data);
                    if (data.wasTapped) {
                        // this.presentToast('Received in background');
                    } else {
                        // this.presentToast('Received in foreground');
                    }
                });
            }
            setTimeout(() => {
                this.splashScreen.hide();
            }, 100);
        });
    }

    private async checkAppVersion() {
        if (!this.helperService.isNative()) {
            return false;
        }
        const url = `app-versions`;
        const param = {
            platform: this.helperService.isAndroid() ? 'android' : 'ios',
            number: this.helperService.versionNumber
        };

        this.httpService.get(url, param, this.httpService.apiVersion.v001).then((response: any) => {
            this.isLatest = response.data.isLatest;
            this.forceUpdate = response.data.forceUpdate;
        }, (error) => {
            console.error(error);
        });
    }

    private async updateFcmToken(fcmToken) {
        const url = `members/update-fcm-token`;
        const param = {};
        const data = new FormData();

        data.append('fcm_token', fcmToken);
        this.httpService.put(url, param, data).then((response: any) => {
            console.log('user/fcm response', response);
        }, (error) => {
            console.error(error);
        });
    }

    public async presentToast(message: string, type: string = 'default', duration: number = 3000) {
        let cssClass = 'toast-custom';
        switch (type) {
            case 'success': cssClass += ' success'; break;
            case 'warning': cssClass += ' warning'; break;
            case 'danger': cssClass += ' danger'; break;
            default: break;
        }
        const toast = await this.toastController.create({ message, duration, cssClass });
        toast.present();
    }

}
