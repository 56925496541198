<div class="modal-dialog-wrapper">
    <ion-icon class="close-button" name="close" (click)="dismiss()"></ion-icon>

    <div class="content">
        <div class="modal-title">Pilih Kategori</div>
        <div class="modal-desc">
            <div class="category-list">
                <div class="category-item" [ngClass]="{'active': item.course_category_id === selected.course_category_id}" (click)="select(item)" *ngFor="let item of data">{{ item.names }}</div>
            </div>
        </div>
    </div>
</div>
