<div class="modal-dialog-wrapper">
    <ion-icon class="close-button" name="close" (click)="dismiss()"></ion-icon>

    <div class="content">
        <div class="modal-title" *ngIf="product.type === 1">Pilih Metode Pembelian</div>
        <div class="modal-title" *ngIf="product.type === 2">Gabung Kelas</div>
        <div class="modal-card">
            <div class="title">Kode Voucher</div>
            <input name="code" type="password" id="join-code" class="modal-input" ngmodel [(ngModel)]="catalog.data.code" placeholder="Masukan Kode Disini" required>
            <button type="button" class="fammi-button {{catalog.data.code === '' ? '' : 'button-primary'}}" [disabled]="catalog.data.code === ''" (click)="catalog.input()">GABUNG KELAS</button>
            <div class="desc">Belum punya kode voucher? <a class="anchor" (click)="catalog.order()">Hubungi Admin</a> </div>
        </div>

        <div class="modal-card" *ngIf="product.type === 1">
            <div class="title">Transfer Bank</div>
            <button type="button" class="fammi-button button-primary" (click)="catalog.buy()">BELI KELAS</button>
        </div>
    </div>
</div>
