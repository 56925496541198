import {Component, Input, OnInit} from '@angular/core';
import {LoadingController, ModalController, NavController, ToastController} from '@ionic/angular';
import {CartService} from '../../../core/helper/cart.service'
import {Network} from '@ionic-native/network/ngx';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../../core/http/http.service';
import {AuthService} from '../../../core/auth/auth.service';
import {HelperService} from '../../../core/helper/helper.service';;
import {Product} from '../../../models/product.model';

@Component({
    selector: 'app-catalog-cart',
    templateUrl: './catalog-cart.page.html',
    styleUrls: ['./catalog-cart.page.scss'],
})
export class CatalogCartPage implements OnInit {

    @Input('product') public product: Product;

    public catalog: {
        processing: boolean,
        loading: boolean,
        data: Product[],
        total(): number,
        back(): void,
        buy(): void,
        get(): void
    } = {
        processing: false,
        loading: false,
        data: [],
        total: () => {
            const that = this.catalog;
            let total = 0;
            for (const item of that.data) {
                total += item.price;
            }
            return total;
        },
        back: () => {
            const that = this.catalog;
            this.navigateTo('/home/catalog');
            this.modalController.dismiss();
        },
        buy: () => {
            const that = this.catalog;
            this.navigateTo(`/transaction/checkout`);
            this.modalController.dismiss();
        },
        get: async () => {
            const that = this.catalog;
            that.data = await this.cartService.get();
        }
    };

    constructor(
        private network: Network,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private loadingController: LoadingController,
        private navController: NavController,
        private toastController: ToastController,
        private modalController: ModalController,
        private httpService: HttpService,
        private authService: AuthService,
        public helperService: HelperService,
        private cartService: CartService
    ) {}

    ngOnInit() {
        this.catalog.get();
    }

    public dismiss() {
        this.modalController.dismiss();
    }

    public confirm() {
        this.modalController.dismiss(true);
    }

    public navigateTo(url: string) {
        this.router.navigateByUrl(url);
    }

    public navigateBack(url: string) {
        this.navController.navigateBack(url);
    }

    public goBack() {
        this.navController.back();
    }

    public async presentToast(message: string, type: string = 'default', duration: number = 3000) {
        let cssClass = 'toast-custom';
        switch (type) {
            case 'success': cssClass += ' success'; break;
            case 'warning': cssClass += ' warning'; break;
            case 'danger': cssClass += ' danger'; break;
            default: break;
        }
        const toast = await this.toastController.create({ message, duration, cssClass });
        toast.present();
    }

}
