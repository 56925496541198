import {Injectable} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Platform} from '@ionic/angular';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    public isBrowser = true;
    public versionNumber = 59;
    public fammiAssets = `https://fammi.ly/assets/`;
    public fammiUploads = `https://fammi.ly/uploads/`;
   
    public playStore = `https://play.google.com/store/apps/details?id=ly.fammi.app`;
    public appStore = `https://apps.apple.com/ph/app/fammi/id1491110705`;

    public phoneAdmin = `62895351800900`;
    public phoneAdminFormated = `+62 89-5351-800-900`;

    private dayNames = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    private dayShortNames = ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'];
    private monthNames = [
        'Januari', 'Februari', 'Maret', 'April',
        'Mei', 'Juni', 'Juli', 'Agustus',
        'September', 'Oktober', 'November', 'Desember'
    ];
    private monthShortNames = [
        'Jan', 'Feb', 'Mar', 'Apr',
        'Mei', 'Jun', 'Jul', 'Agu',
        'Sep', 'Okt', 'Nov', 'Des'
    ];

    constructor(
        private platform: Platform,
        private inAppBrowser: InAppBrowser,
        private sanitizer: DomSanitizer
    ) {
    }

    public isAndroid(): boolean {
        return this.platform.is('android');
    }

    public isIos(): boolean {
        return this.platform.is('ios');
    }

    public isNative(): boolean {
        return (this.isAndroid() || this.isIos()) && !this.isBrowser;
    }

    public sameDate(from: string, to: string): boolean {
        const dateFrom = new Date(from);
        const dateTo = new Date(to);
        const formatedFrom = `${dateFrom.getFullYear()}${dateFrom.getMonth()}${dateFrom.getDate()}`;
        const formatedTo = `${dateTo.getFullYear()}${dateTo.getMonth()}${dateTo.getDate()}`;
        return formatedFrom === formatedTo;
    }

    public sameMonth(from: string, to: string): boolean {
        const dateFrom = new Date(from);
        const dateTo = new Date(to);
        const formatedFrom = `${dateFrom.getFullYear()}${dateFrom.getMonth()}`;
        const formatedTo = `${dateTo.getFullYear()}${dateTo.getMonth()}`;
        return formatedFrom === formatedTo;
    }

    public sameYear(from: string, to: string): boolean {
        const dateFrom = new Date(from);
        const dateTo = new Date(to);
        const formatedFrom = `${dateFrom.getFullYear()}`;
        const formatedTo = `${dateTo.getFullYear()}`;
        return formatedFrom === formatedTo;
    }

    public timestamp(date: Date): string {
        let month = `${date.getMonth() + 1}`;
        if (parseInt(month, 0) < 10) { month = `0${month}`; }
        let day = `${date.getDate()}`;
        if (parseInt(day, 0) < 10) { day = `0${day}`; }
        let hour = `${date.getHours()}`;
        if (parseInt(hour, 0) < 10) { hour = `0${hour}`; }
        let minute = `${date.getMinutes()}`;
        if (parseInt(minute, 0) < 10) { minute = `0${minute}`; }
        let second = `${date.getSeconds()}`;
        if (parseInt(second, 0) < 10) { second = `0${second}`; }
        return `${date.getFullYear()}-${month}-${day}T${hour}:${minute}:${second}`;
    }

    public timeRemaining(duration: number): Array<string> {
        const hour = Math.floor(duration / 3600);
        const minute = Math.floor((duration - (hour * 3600)) / 60);
        const second = duration - (hour * 3600) - (minute * 60);
        return [
            hour < 10 ? `0${hour}` : `${hour}`,
            minute < 10 ? `0${minute}` : `${minute}`,
            second < 10 ? `0${second}` : `${second}`
        ];
    }

    public date(timestamp: string): Date {
        return new Date(timestamp.replace(' ', 'T'));
    }

    public getDate(timestamp: string): number {
        return this.date(timestamp).getDate();
    }

    public getDayNames(timestamp: string): string {
        const day = this.date(timestamp).getDay();
        return day < this.dayNames.length ? this.dayNames[day] : '-';
    }

    public getDayShortNames(timestamp: string): string {
        const day = this.date(timestamp).getDay();
        return day < this.dayShortNames.length ? this.dayShortNames[day] : '-';
    }

    public getMonthNames(timestamp: string): string {
        const month = this.date(timestamp).getMonth();
        return month < this.monthNames.length ? this.monthNames[month] : '-';
    }

    public getMonthShortNames(timestamp: string): string {
        const month = this.date(timestamp).getMonth();
        return month < this.monthShortNames.length ? this.monthShortNames[month] : '-';
    }

    public getFullYear(timestamp: string): string {
        return `${this.date(timestamp).getFullYear()}`;
    }

    public getFormatedTime(timestamp: string): string {
        const date = this.date(timestamp);
        const hour = date.getHours();
        const minute = date.getMinutes();
        return `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
    }

    public getFormatedDate(timestamp: string): string {
        const date = this.date(timestamp);
        const day = date.getDay();
        const month = date.getMonth();
        const year = date.getFullYear();
        const dayName = day < this.dayNames.length ? this.dayNames[day] : '-';
        const monthName = month < this.monthNames.length ? this.monthNames[month] : '-';
        return `${dayName}, ${date.getDate()} ${monthName} ${year}`;
    }

    public getFormatedDateShort(timestamp: string): string {
        const date = this.date(timestamp);
        const day = date.getDay();
        const month = date.getMonth();
        const year = date.getFullYear();
        const dayName = day < this.dayNames.length ? this.dayNames[day] : '-';
        const monthName = month < this.monthShortNames.length ? this.monthShortNames[month] : '-';
        return `${dayName}, ${date.getDate()} ${monthName} ${year}`;
    }

    public getFormatedDateTime(timestamp: string): string {
        const date = this.date(timestamp);
        const month = date.getMonth();
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const monthName = month < this.monthNames.length ? this.monthNames[month] : '-';
        return `${date.getDate()} ${monthName} ${year} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
    }

    public getFormatedDateWithoutDay(timestamp: string): string {
        const date = this.date(timestamp);
        const month = date.getMonth();
        const year = date.getFullYear();
        const monthName = month < this.monthNames.length ? this.monthNames[month] : '-';
        return `${date.getDate()} ${monthName} ${year}`;
    }

    public getFormatedDateShortWithoutDay(timestamp: string): string {
        const date = this.date(timestamp);
        const month = date.getMonth();
        const year = date.getFullYear();
        const monthName = month < this.monthShortNames.length ? this.monthShortNames[month] : '-';
        return `${date.getDate()} ${monthName} ${year}`;
    }

    public getFormatedDateRange(from: string, to: string): string {
        let result = '';
        if (this.sameDate(from, to) === false) {
            result += ` ${this.getDate(from)}`;
        }
        if (this.sameMonth(from, to) === false) {
            result += ` ${this.getMonthNames(from)}`;
        }
        if (this.sameYear(from, to) === false) {
            result += ` ${this.getFullYear(from)}`;
        }
        if (result !== '') {
            result += ` - `;
        }
        result += `${this.getFormatedDateWithoutDay(to)}`;
        return result;
    }

    public dateIsPassed(timestamp: string): boolean {
        let passed = false;
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        if (this.date(timestamp) < today) {
            passed = true;
        }
        return passed;
    }

    public ratingCaption(rating?: number): string {
        let caption = `Isi Rating`;
        switch (rating) {
            case 1: caption = `Sangat Buruk`; break;
            case 2: caption = `Buruk`; break;
            case 3: caption = `Cukup`; break;
            case 4: caption = `Baik`; break;
            case 5: caption = `Sangat Baik`; break;
        }
        return caption;
    }

    public trimWords(text: string, length: number = 80) {
        let trimmedString = text;
        if (trimmedString.length > length) {
            trimmedString = text.substr(0, length);
            trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))) + '....';
        }
        return trimmedString;
    }

    public getVideoUrl(video: string): SafeResourceUrl {
        let embedUrl = `https://www.youtube.com/embed/${video}?hl=id-ID&rel=0&showinfo=0&enablejsapi=1&fs=1`;
        if (video.includes('sproutvideo.com')) {
            embedUrl = video;
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    }

    public openURL(url: string) {
        this.inAppBrowser.create(url, '_system');
    }

    public openWhatsApp(to: string, message: string) {
        const encodedMessage = encodeURIComponent(message);
        const url = `https://wa.me/${to}?text=${encodedMessage}`;
        this.openURL(url);
    }

    public async openStore() {
        if (!this.isNative()) {
            return false;
        }
        this.inAppBrowser.create(this.isAndroid() ? this.playStore : this.appStore, '_system');
    }

    public addHttp(url: string) {
        if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
            url = `http://${url}`;
        }
        return url;
    }

    public b64toBlob(dataURI: string) {

        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }
}

export const firebaseConfig = {
    apiKey: 'AIzaSyBfwVNEz2g8pCzF6SpI2NHtvHrh4NaPxL8',
    authDomain: 'fammi-4359c.firebaseapp.com',
    databaseURL: 'https://fammi-4359c.firebaseio.com',
    projectId: 'fammi-4359c',
    storageBucket: '',
    messagingSenderId: '664477676821',
    appId: '1:664477676821:web:a068af25bc5542fc'
};
