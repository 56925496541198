<div class="modal-dialog-wrapper">
    <ion-icon class="close-button" name="close" (click)="dismiss()"></ion-icon>

    <div class="content">
        <div class="modal-title">Pilih Usia</div>
        <div class="modal-desc">
            <div class="category-card">
                <div class="category-item" *ngFor="let item of data">
                    <div class="category-wrapper" [ngClass]="{'active': item.course_category_id === selected.course_category_id, 'default': item.is_default}" (click)="select(item)">
                        <div class="image" [style.backgroundImage]="'url(' + item.images + ')'"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
