<ion-app [ngClass]="{'is-browser': helperService.isBrowser, 'is-ios': helperService.isIos() && !helperService.isBrowser}">
    <ion-router-outlet></ion-router-outlet>
</ion-app>
<div class="app-update" *ngIf="isLatest === false && skipUpdate === false">
    <img src="assets/images/app-update.png" width="200px">
    <div class="title">Fammi Versi Terbaru</div>
    <div class="desc">Anda menggunakan versi lama Fammi, update Fammi untuk mendapatkan fitur terbaru, dan performa yang lebih baik</div>
    <button type="button" class="fammi-button button-block button-secondary" (click)="helperService.openStore()">UPDATE</button>
    <button type="button" class="fammi-button button-block button-clear" (click)="skipUpdate = true" *ngIf="forceUpdate === false">LEWATI</button>
</div>
