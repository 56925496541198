import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import {Product} from '../../models/product.model';
import {EventsService} from '../../services/events/events.service';

@Injectable({
    providedIn: 'root'
})
export class CartService {

    constructor(
        private router: Router,
        private storage: Storage,
        private events: EventsService
    ) {}

    public async count(): Promise<number> {
        const cart = await this.get();
        return cart.length;
    }

    public async get(): Promise<Product[]> {
        const getCart = await this.storage.get('CART');
        return getCart ? JSON.parse(getCart) : [];
    }

    public async exists(product): Promise<boolean> {
        let exist = false;
        const cart = await this.get();
        if (cart.length) {
            for (const item of cart) {
                if (item.product_id === product.product_id) {
                    exist = true;
                    break;
                }
            }
        }
        return exist;
    }

    public async add(product: Product) {
        const exists = await this.exists(product);
        if (exists === false) {
            const cart = await this.get();
            cart.push(product);
            await this.storage.set('CART', JSON.stringify(cart));
            this.events.publish(`onChangeCartData`);
        }
    }

    public async remove(product: Product) {
        let index = -1;
        const cart = await this.get();
        if (cart.length) {
            for (let i = 0; i < cart.length; i++) {
                if (cart[i].product_id === product.product_id) {
                    index = i;
                }
            }
        }
        if (index > -1) {
            cart.splice(index, 1);
            await this.storage.set('CART', JSON.stringify(cart));
            this.events.publish(`onChangeCartData`);
        }
    }

    public async destroy() {
        await this.storage.remove('CART');
        this.events.publish(`onChangeCartData`);
    }

}
