<div class="modal-dialog-wrapper">
    <div class="content">
        <div class="modal-header">
            <div class="modal-icon"><i class="icon-check"></i></div>
            <div class="modal-caption">Berhasil masuk keranjang</div>
        </div>

        <br />

        <div class="cart-wrapper">
            <div class="cart-item">
                <div class="card">
                    <div class="header">
                        <div class="image" [style.backgroundImage]="'url(' + product.image + ')'"></div>
                    </div>
                    <div class="body">
                        <div class="info">
                            <div class="title">Kelas dimulai</div>
                            <div class="date">{{helperService.getDate(product.from)}}{{ helperService.sameMonth(product.from, product.to) ? '' : ' ' + helperService.getMonthNames(product.from) }}{{ helperService.sameYear(product.from, product.to) ? '' : ' ' + helperService.getFullYear(product.from) }} - {{helperService.getFormatedDateWithoutDay(product.to)}}</div>
                        </div>
                        <div class="action">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-separator offset"></div>

        <div class="modal-subtitle space-between">
            <span>Sub Total ({{catalog.data.length}} Kelas)</span>
            <span>Rp.{{catalog.total() | number}}</span>
        </div>
        <br />

        <div class="modal-action">
            <button type="button" class="fammi-button button-primary" (click)="catalog.buy()">LANJUT KE PEMBELIAN</button>
            <button type="button" class="fammi-button button-outline-primary" (click)="catalog.back()">BELI KELAS LAGI</button>
        </div>
    </div>
</div>
