import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {CatalogBuyPage} from './modals/catalog/catalog-buy/catalog-buy.page';
import {CatalogCartPage} from './modals/catalog/catalog-cart/catalog-cart.page';
import {FormsModule} from '@angular/forms';
import { Zoom } from '@ionic-native/zoom/ngx';
import {SafeHtmlModule} from './pipes/safe-html/safe-html.module';
import {CategoryContentPage} from './modals/category/category-content/category-content.page';
import {CategoryAgePage} from './modals/category/category-age/category-age.page';
import {CategoryJobPage} from './modals/category/category-job/category-job.page';

@NgModule({
    declarations: [
        AppComponent,
        CatalogBuyPage,
        CatalogCartPage,
        CategoryContentPage,
        CategoryAgePage,
        CategoryJobPage
    ],
    entryComponents: [
        CatalogBuyPage,
        CatalogCartPage,
        CategoryContentPage,
        CategoryAgePage,
        CategoryJobPage
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        CoreModule,
        FormsModule,
        SafeHtmlModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        Zoom,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
