export class FilePreview {
    public name?: string;
    public current: string;
    public preview: string;
    public remove?(): void;
}

export class ApiResponse {
    public success: boolean;
    public message: string;
}
