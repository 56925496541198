import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicStorageModule} from '@ionic/storage';
import {HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './auth/auth.guard';
import {Network} from '@ionic-native/network/ngx';
import {FCM} from '@ionic-native/fcm/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {firebaseConfig} from './helper/helper.service';

@NgModule({
    declarations: [],
    entryComponents: [],
    imports: [
        CommonModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFirestoreModule
    ],
    providers: [
        AuthGuard,
        Network,
        FCM,
        InAppBrowser
    ]
})
export class CoreModule {
}
